import React from "react"

import Layout from "../../components/lb/layout-maintenance"

const ErrorPage = () => {

    var  errorSeo = { metaTitle:'404: Nët fond', metaDescription:'Dir hud en Url obgeruff, deen net existeiert.'};

    return (
    <Layout pageen="/error3" pagelb="/fehler3" seo={errorSeo}>
        <section id="banner">
            <div className="inner">
                <h2 className="mt-5">404: Nët fond</h2>
                <p>Dir hud en Url obgeruff, deen net existeiert.</p>
            </div>
        </section>
    </Layout>
    );
}

export default ErrorPage
